<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="소화설비명"
                  name="fireFightingName"
                  v-model="fireFighting.fireFightingName">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="관리번호"
                  name="manageNo"
                  v-model="fireFighting.manageNo">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="fireFighting.plantCd" />
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-select
                  :editable="editable"
                  codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingTypeCd"
                  label="설비유형"
                  v-model="fireFighting.sopFireFightingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-select
                  :editable="editable"
                  :comboItems="kindItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingKindCd"
                  label="설비종류"
                  v-model="fireFighting.sopFireFightingKindCd"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-text
                  :editable="editable"
                  label="수량"
                  type="number"
                  name="amount"
                  v-model="fireFighting.amount">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-3 col-lg-3">
                <c-select
                  :editable="editable"
                  codeGroupCd="SOP_FIRE_FIGHTINH_REPLACEMENT_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  label="교체주기"
                  name="sopFireFightinhReplacementCycleCd"
                  v-model="fireFighting.sopFireFightinhReplacementCycleCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-danger-area
                  :editable="editable"
                  :plantCd="fireFighting.plantCd"
                  label="관련 위험물저장소"
                  name="chmDangerMstId"
                  v-model="fireFighting.chmDangerMstId"
                ></c-danger-area>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-dept-multi 
                  :editable="editable"
                  :parentCheckDepts="fireFighting.deptCd" 
                  :plantCd="fireFighting.plantCd" 
                  label="관리부서" 
                  name="deptCd" 
                  v-model="fireFighting.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-process
                  :editable="editable"
                  label="관련공정"
                  multiple="multiple"
                  name="processCd"
                  v-model="fireFighting.processCd">
                </c-process>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-card title="설치관련 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  label="설치위치"
                  name="location"
                  v-model="fireFighting.location">
                </c-text>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :editable="editable"
                  label="설치일"
                  name="installDate"
                  v-model="fireFighting.installDate"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <c-card title="설치상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
                <c-select
                  :editable="editable"
                  :comboItems="disposeFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="disposeFlag"
                  label="폐기여부"
                  v-model="fireFighting.disposeFlag"
                ></c-select>
              </div>
              <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :required="fireFighting.disposeFlag==='Y'"
                  :editable="editable"
                  :disabled="fireFighting.disposeFlag==='N'"
                  label="폐기일"
                  name="disposeDate"
                  v-model="fireFighting.disposeDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-table
        v-if="isOld"
        ref="table"
        isTitle
        title="교체이력"
        :gridHeightAuto="true"
        :columns="grid.columns"
        :data="fireFighting.replacementHistroies"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        :isExcelDown="false"
        :isFullScreen="false"
        selection="multiple"
        rowKey="sopFireFightingReplacementHistoryId"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable" 
              :showLoading="false" 
              label="추가" 
              icon="add" 
              @btnClicked="addReplacementHistory" />
            <c-btn 
              v-if="editable" 
              label="제외" 
              icon="remove" 
              @btnClicked="deleteReplacementHistory" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customDetail'">
            <q-btn
              flat
              size="12px"
              color="orange"
              icon="chevron_right"
              @click="rowClickDetail(props.row, props.rowIndex)" />
          </template>
        </template>
      </c-table>
    </q-form>
    <q-dialog v-model="dialogDetail" position="bottom">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-form ref="editDialogForm">
          <q-card-section class="row">
            <div class="col-12 text-weight-bold-dailog q-mb-sm">교체정보</div>
            <div class="col-12">
              <c-datepicker
                :required="true"
                :editable="editable"
                :minuteStep="10"
                type="datetime"
                label="교체일시"
                name="replacementDt"
                v-model="selectedRow.replacementDt"
              />
            </div>
            <div class="col-12">
              <c-field
                :required="true"
                :editable="editable"
                label="교체자"
                name="issuedUserId"
                v-model="selectedRow.replacementUserId" />
            </div>
            <div class="col-12">
              <c-textarea
                :editable="editable"
                label="교체내용"
                name="replacementContents"
                v-model="selectedRow.replacementContents" />
            </div>
            <div class="col-12">
              <c-textarea
                :editable="editable"
                label="비고"
                name="remarks"
                v-model="selectedRow.remarks" />
            </div>
            <div class="col-12 mini-dailog-btn-impr">
              <q-btn-group outline>
                <c-btn
                  v-if="editable"
                  label="적용"
                  icon="check"
                  @btnClicked="acceptReplacement" />
                <c-btn
                  :showLoading="false"
                  label="닫기"
                  icon="close"
                  @btnClicked="dialogClose"
                />
              </q-btn-group>
            </div>
          </q-card-section>
        </q-form>
      </q-card>
    </q-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="fireFighting"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        sopFireFightingKindCd: null,  // 점검 소방시설 종류
        sopFireFightingKindName: '',  // 점검 소방시설 종류
        manageNo: '',  // 관리번호
        fireFightingName: '',  // 소방설비명
        deptCd: '',  // 관리부서
        processCd: '',  // 관련공정
        sopFireFightinhReplacementCycleCd: '',  // 소방설비 교체 주기
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        location: '',  // 설비위치
        installDate: '',  // 설치일
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        deleteFlag: 'N',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        replacementHistroies: [],
        deleteReplacementHistroies: [],
        histroy: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'replacementDt',
            field: 'replacementDt',
            // 교체일시
            label: '교체일시',
            align: 'center',
            style: 'width:40%',
            // type: 'datetime',
            // datetimeType: 'datetime',
            // minuteStep: 10,
            sortable: false,
            // required: true,
          },
          // {
          //   name: 'replacementUserName',
          //   field: 'replacementUserName',
          //   // 교체자
          //   label: '교체자',
          //   align: 'center',
          //   style: 'width:120px',
          //   type: 'user',
          //   userId: 'replacementUserId',
          //   sortable: false,
          //   required: true,
          // },
          {
            name: 'replacementContents',
            field: 'replacementContents',
            // 교체 내용
            label: '교체 내용',
            align: 'left',
            style: 'width:60%',
            // type: 'textarea',
            sortable: false,
          },
          // {
          //   name: 'remarks',
          //   field: 'remarks',
          //   // 비고
          //   label: '비고',
          //   align: 'left',
          //   style: 'width:100px',
          //   type: 'textarea',
          //   sortable: false,
          // },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
        ],
        height: '350px'
      },
      selectedRow: {
        replacementDt: '',
        replacementUserId: '',
        replacementUserName: '',
        replacementContents: '',
        remarks: '',
      },
      selectedRowIndex: -1,
      kindItems: [],
      dialogDetail: false,
      editable: true,
      isSave: false,
      saveUrl: '',
      checkUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'fireFighting.sopFireFightingTypeCd'() {
      this.setKind();
    }
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.checkUrl = selectConfig.sop.fft.fireFighting.check.url
      this.saveUrl = transactionConfig.sop.fft.fireFighting.update.url
      // code setting
      // list setting
      this.setKind();
    },
    setKind() {
      if (this.fireFighting.sopFireFightingTypeCd) {
        this.$comm.getComboItems('SOP_FIRE_FIGHTING_KIND_CD', this.fireFighting.sopFireFightingTypeCd).then(_result => {
          this.kindItems = _result;
          if (this.$_.findIndex(this.kindItems, { code: this.fireFighting.sopFireFightingKindCd }) === -1) {
            this.$set(this.fireFighting, 'sopFireFightingKindCd', null)
          }
        });
      } else {
        this.kindItems = [];
      }
    },
    addReplacementHistory() {
      this.selectedRowIndex = -1
      this.$_.extend(this.selectedRow, {
        sopFireFightingReplacementHistoryId: uid(),  // 소방설비 교체 이력 일련번호
        sopFireFightingId: this.popupParam.sopFireFightingId,  // 소방설비 일련번호
        replacementDt: this.$comm.getTodayDateTiemMin(),  // 교체일시
        replacementUserId: this.$store.getters.user.userId,  // 교체자
        replacementUserName: this.$store.getters.user.userName,  // 교체자
        replacementContents: '',  // 교체 내용
        remarks: '',  // 비고
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C',
      });
      this.dialogDetail = true;
    },
    rowClickDetail(row, rowIndex) {
      this.dialogDetail = true;
      this.$_.extend(this.selectedRow, row);
      this.selectedRowIndex = rowIndex;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    acceptReplacement() {
      this.$refs['editDialogForm'].validate().then(_result => {
        if (_result) {
          if (this.selectedRowIndex > -1) {
            this.$_.extend(this.fireFighting.replacementHistroies[this.selectedRowIndex], this.selectedRow)
          } else {
            this.fireFighting.replacementHistroies.splice(0, 0, this.selectedRow)
          }
          this.dialogClose();
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });

    },
    deleteReplacementHistory() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?', 
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.fireFighting.deleteReplacementHistroies) this.fireFighting.deleteReplacementHistroies = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.fireFighting.deleteReplacementHistroies, { sopFireFightingReplacementHistoryId: item.sopFireFightingReplacementHistoryId }) === -1) {
                  this.fireFighting.deleteReplacementHistroies.push(item)
                }
              this.fireFighting.replacementHistroies = this.$_.reject(this.fireFighting.replacementHistroies, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
          
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.fft.fireFighting.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.fft.fireFighting.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl
          this.$http.type = 'GET';
          this.$http.param = {
            sopFireFightingId: this.popupParam.sopFireFightingId,
            manageNo: this.fireFighting.manageNo,
          }
          this.$http.request((_result) => {
            if (!_result.data) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.fireFighting.regUserId = this.$store.getters.user.userId;
                  this.fireFighting.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '동일한 관리번호가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingId', result.data)
      this.$emit('getDetail');
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>